import React from 'react';
import s from './CartItem.scss';
import {CartItemThumbnail} from '../CartItemThumbnail/CartItemThumbnail';
import {Name} from './partials/Name/Name';
import {Discounts} from './partials/Discounts/Discounts';
import {Options} from './partials/Options/Options';
import {Price} from './partials/Price/Price';
import {Remove} from './partials/Remove/Remove';
import {ICartItem} from '../../../../types/app.types';
import {Quantity} from './partials/Quantity/Quantity';
import {TotalPrice} from './partials/TotalPrice/TotalPrice';
import {OutOfStock} from './partials/OutOfStock/OutOfStock';
import {PaymentTypeLabel} from './partials/PaymentTypeLabel/PaymentTypeLabel';
import {hasFreeText, hasOptions} from '../../../../domain/utils/itemUtils';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';
import {PaymentOptionType, Severity, Violation} from '@wix/wixstores-graphql-schema-node';
import {ViolationNotification} from '../../Violations/ViolationNotification/ViolationNotification';
import {guidToInt} from '../../../../domain/utils/guiToInt';

export enum CartItemDataHook {
  'ComparePrice' = 'CartItemDataHook.comparePrice',
  'Name' = 'CartItemDataHook.name',
  'Options' = 'CartItemDataHook.options',
  'FreeText' = 'CartItemDataHook.freeText',
  'Price' = 'CartItemDataHook.price',
  'PaymentTypeLabel' = 'CartItemDataHook.paymentTypeLabel',
  'Quantity' = 'CartItemDataHook.quantity',
  'QuantityErrorTooltip' = 'CartItemDataHook.quantityErrorTooltip',
  'OutOfStockRoot' = 'CartItemDataHook.outOfStockRoot',
  'OutOfStockError' = 'CartItemDataHook.outOfStockError',
  'Remove' = 'CartItemDataHook.remove',
  'TotalPrice' = 'CartItemDataHook.totalPrice',
  'Discounts' = 'CartItemDataHook.Discounts',
  'DiscountName' = 'CartItemDataHook.DiscountName',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CartItem = ({item}: {item: ICartItem}) => {
  const {
    cartStore: {
      shouldDisplayViolations,
      cart: {violations},
    },
  } = useControllerProps();
  const shouldHideQuantity = item.renderingConfig?.hideQuantity;
  const shouldHidePrice = item.renderingConfig?.hidePrice;
  const shouldShowPaymentTypeLabel = item.paymentType && item.paymentType !== PaymentOptionType.FULL_PAYMENT_ONLINE;
  const isItemOutOfStock = item.inventoryQuantity === 0;

  const getLineItemViolationIfItExists = (): Violation | undefined => {
    if (shouldDisplayViolations) {
      return (
        violations.find(
          (violation) =>
            guidToInt(violation.target?.lineItem?.id) === item.cartItemId && violation.severity === Severity.ERROR
        ) ??
        violations.find(
          (violation) =>
            guidToInt(violation.target?.lineItem?.id) === item.cartItemId && violation.severity === Severity.WARNING
        )
      );
    }
  };

  const lineItemViolation = getLineItemViolationIfItExists();
  const shouldShowLineItemViolation = !isItemOutOfStock && Boolean(lineItemViolation);

  return (
    <div className={s.itemWrapper}>
      <div className={s.item}>
        <div className={s.info}>
          <CartItemThumbnail item={item} />
          <div className={s.details}>
            <div className={s.content}>
              <Name item={item} />
              <Discounts item={item}></Discounts>
              {!shouldHidePrice && <Price item={item} />}
              {shouldShowPaymentTypeLabel && <PaymentTypeLabel item={item} />}
              {hasOptions(item) && (
                <Options
                  itemId={item.cartItemId}
                  options={item.optionsSelectionsValues}
                  dataHook={CartItemDataHook.Options}
                />
              )}
              {hasFreeText(item) && (
                <Options itemId={item.cartItemId} options={item.freeText} dataHook={CartItemDataHook.FreeText} />
              )}
            </div>
            {
              <div className={s.priceAndQuantity}>
                {!shouldHideQuantity && <Quantity item={item} key={`item-${item.cartItemId}-${item.quantity}`} />}
                <TotalPrice item={item} />
              </div>
            }
          </div>
        </div>
        <div className={s.colEnd}>
          <Remove item={item} />
        </div>
      </div>
      {isItemOutOfStock && <OutOfStock />}
      {shouldShowLineItemViolation && (
        <div className={s.violation}>
          <ViolationNotification violation={lineItemViolation} />
        </div>
      )}
    </div>
  );
};
