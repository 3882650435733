import React from 'react';
import {Header} from './Components/Header/Header';
import {classes, st} from './SideCart.st.css';
import {Footer} from './Components/Footer/Footer';
import {Body} from './Components/Body/Body';

export const SideCart = () => {
  return (
    <main className={st('cartBodyMainOneColumn', classes.container)}>
      <div className={classes.headerAndBody}>
        <Header />
        <Body />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </main>
  );
};
