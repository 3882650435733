import React from 'react';
import {WidgetProps} from '@wix/yoshi-flow-editor';
import {IControllerProps} from '../../../types/app.types';
import {GeneralError} from '../../../common/components/GeneralError/GeneralError';
import {ControllerContext} from '../../../domain/controllers/ControllerContext';
import {SideCart} from './SideCart';

const SideCartAppRoot = (controllerProps: WidgetProps<IControllerProps>) => {
  if (controllerProps.generalError) {
    return <GeneralError />;
  }

  return (
    <ControllerContext value={controllerProps}>
      <SideCart />
    </ControllerContext>
  );
};

export default SideCartAppRoot;
