import React from 'react';
import s from './SecureCheckout.scss';
import Lock from '../../../assets/images/Lock.svg';

export enum SecureCheckoutDataHook {
  lock = 'SecureCheckoutDataHook.lock',
  text = 'SecureCheckoutDataHook.text',
  root = 'SecureCheckoutDataHook.root',
}

export const SecureCheckout = ({text}: {text: string}) => {
  return (
    <div className={s.secureCheckout} data-hook={SecureCheckoutDataHook.root}>
      <Lock className={s.secureCheckoutLock} data-hook={SecureCheckoutDataHook.lock} />
      <span className={s.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
        {text}
      </span>
    </div>
  );
};
