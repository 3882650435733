import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';

type IStylesParams = {
  cart_textFontStyle: StyleParamType.Font;
  cartHeaderBackgroundColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cartHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 12),
  },
});
