import React from 'react';
import {ICartItem} from '../../../../types/app.types';
import {ProductThumbnail} from '@wix/wixstores-client-common-components';
import s from './CartItemThumbnail.scss';
import {ItemLink} from '../CartItem/partials/ItemLink/ItemLink';
import {getMediaDimensions} from '../../../../domain/utils/itemUtilsWithSideEffect';

export enum CartItemThumbnailDataHook {
  Image = 'CartItemThumbnailDataHook.image',
}

export const CartItemThumbnail = ({item}: {item: ICartItem}) => {
  return (
    <div className={s.thumbnail}>
      <ItemLink item={item}>
        <ProductThumbnail
          product={item.product}
          {...getMediaDimensions(item, CartItemThumbnail.defaultDimensions)}
          mountedToDOM={true}
        />
      </ItemLink>
    </div>
  );
};

CartItemThumbnail.defaultDimensions = {width: 100, height: 100};
